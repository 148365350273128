import {ReactNode, useState} from "react";
import {signIn} from "next-auth/react";
import BlankLayout from "../@core/layouts/BlankLayout";

// ** MUI Imports
import {React} from "mdi-material-ui";
import Grid from '@mui/material/Grid'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Button, TextField} from "@mui/material";

// ** Component
import {setMsgText} from "../hooks/useCommon";

// ** Style
import styles from "../../public/styles/pages/Login.module.css";

// ** Image
import Brand from "../../public/images/logo/logo.svg";
import Logo from "../../public/images/logo/img_login_logo.svg";
import {useSnackBar} from "../component/common/SnackbarContext";

/**
 * 로그인
 */
interface LoginProps {
    email: string; // 이메일
    password: string; // 비밀번호
}

/**
 * 로그인 화면
 * @constructor
 */
const Login = () => {
    const snackbar = useSnackBar();
    // 데이터
    const [ data, setData ] = useState<LoginProps>({
        email: '', // 이메일
        password: '' // 비밀번호
    });

    /**
     * @desc 이메일 Change Event
     * @param e
     */
    const doEmailChange = (e) => {
        setData({...data, email: e.target.value});
    }

    /**
     * @desc 비밀번호 Change Event
     * @param e
     */
    const doPasswordChange = (e) => {
        setData({...data, password: e.target.value});
    }

    /**
     * @desc 비밀번호 KeyPress 이벤트
     * @param e
     */
    const doEnter = (e) => {
        if (e.charCode === 13) {
            doLogin().then();
        }
    }

    /**
     * @desc 유효성 체크
     */
    const doValid = () => {
        // 이메일
        if (!data.email) {
            snackbar.doShow(setMsgText('required', '이메일'), 'error');
            return false;
        }

        // 비밀번호
        if (!data.password) {
            snackbar.doShow(setMsgText('required', '비밀번호'), 'error');
            return false;
        }
        return true;
    }

    /**
     * @desc 로그인 Process
     */
    const doLogin = async () => {
        const isValid = doValid();

        if (isValid === false) return false;

        // 로그인 (NextAuth)
        const res = await signIn("credentials", {
            email: data.email,
            password: data.password,
            redirect: false,
            // callbackUrl: "/",
        });

        // 성공이 아닐 경우
        if (res.status !== 200) {
            snackbar.doShow(setMsgText('ext', res.error), 'error');
            return false;
        } else {
            location.href = '/'; // 메인 Redirect
        }
    }
    return (
        <Grid container className={styles.container}>
            <Card className={styles.wrap}>
                <CardContent className={styles.area}>
                    <Grid container>
                        {/* 상단영역 */}
                        <Grid className={styles.top}>
                            {/* 브랜드 로고 */}
                            <Grid className={styles.brand}>
                                <Brand width={70} height={17} />
                            </Grid>
                            {/* 로고 */}
                            <Grid item xs={12} className={styles.logo}>
                                <Logo width={160} height={45}/>
                            </Grid>
                        </Grid>
                        {/* 입력영역 */}
                        <Grid container spacing={3} className={styles.middle}>
                            {/* 이메일 */}
                            <Grid item xs={12}>
                                <TextField fullWidth type="email" label="이메일" value={data.email} onChange={doEmailChange}/>
                            </Grid>
                            {/* 비밀번호 */}
                            <Grid item xs={12}>
                                <TextField fullWidth type="password" variant="outlined" label="비밀번호"  value={data.password} onChange={doPasswordChange} onKeyPress={doEnter}/>
                            </Grid>
                        </Grid>
                        {/* 버튼영역 */}
                        <Grid className={styles.bottom}>
                            <Grid item xs={12}>
                                <Button component="a" variant="contained" className={'btn-comm'} onClick={() => doLogin()}>로그인</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

Login.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

export default Login
